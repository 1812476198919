import React, { useState, useEffect } from 'react';

import ReactGA from 'react-ga4';

const usePageTracking = () => {
  const location = window.location.href

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location });
  }, [location]);
};


function ChatApp() {
  usePageTracking();
  // Initialize chat history with demo messages
  const [chatHistory, setChatHistory] = useState([
    { id: 1, query: <>Welcome to CareerUpNow.org’s Self-Advocacy Guide for Addressing Antisemitism & Anti-Israel Sentiment on Campus and in the Workplace. 
<br /> <br />
Career Up Now designed the Self-Advocacy Guide & AI chatbot to equip Jewish professionals, college students, & their allies with instant, personalized responses to antisemitism, anti-Israel sentiment, and antizionism in the workplace & on campus.
<br /> <br />  
Both aim to foster a safer, more inclusive environment where Jewish identity is respected & valued.
<br /> <br /> 
Career Up Now’s mission is to equip college students & professionals for career advancement, while incorporating Jewish wisdom into their lives. Please check out our innovative networking opportunities with industry leaders & other resources if you are looking to lead a more productive Jewish life.
<br /> <br />
To access the 32-page Self-Advocacy Guide, please click <a target="_blank" className="text-blue-500 underline" href='https://www.careerupnow.org/self-advocacy-guide'>https://www.careerupnow.org/self-advocacy-guide</a> for a downloadable companion resource.
<br /> <br />
By using these resources you agree to our user terms <a target="_blank" className="text-blue-500 underline" href='https://www.careerupnow.org/legal-terms'>https://www.careerupnow.org/legal-terms</a>.`</>
    , sender: 'received' }
  ]);

  const stockMessages = [
    { id: 2, query: <p><b>Now, how may I help you today?</b><br /> <br /><i>Please type in the situation you are facing below, and I’ll provide strategies & support.  Feel free to use your primary language, as I am able to give assistance in over 20 languages.</i></p>, sender: 'received' },];

  // just show those messages one by one and wait for 1 second show the typing animation
  useEffect(() => {
    const interval = setInterval(() => {
      setIsTyping(false);
      if (stockMessages.length > 0) {
        const message = stockMessages.shift();
        setChatHistory((chatHistory) => [...chatHistory, message]);
        setTimeout(() => {
          // setIsTyping(true);
        }, 1000);
      } else {
        clearInterval(interval);
        setIsTyping(false); // Set typing to false when all stock messages are displayed
        setIsTextBoxActive(true);
      }
    }, 2000);
    setIsTyping(true); // Set typing to true when the interval starts
    return () => clearInterval(interval);
  }, []);

  const [reversedChatHistory, setReversedChatHistory] = useState([...chatHistory].reverse());

  const [isTyping, setIsTyping] = useState(false);

  const [chatHistoryAPI, setChatHistoryAPI] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const [isTextBoxActive, setIsTextBoxActive] = useState(false);

  // const getLinkBasedText = (text) => {
  //   // make the link clickable only
  //   const link = text.match(/(https?:\/\/[^\s]+)/g);
  //   if (link) {
  //     const linkText = text.split(link[0]);
  //     return (
  //       <a href={link[0]} target="_blank" rel="noreferrer">
  //         {linkText[0]}
  //         <span className="text-blue-500 underline">{link[0]}</span>
  //         {linkText[1]}
  //       </a>
  //     );
  //   }
  //   return text;
  // }
  

  useEffect(() => {
    // Scroll to the bottom of the chat history on update
    const chatContainer = document.getElementById('chatContainer');
    chatContainer.scrollTop = chatContainer.scrollHeight;

    setReversedChatHistory([...chatHistory].reverse());
  }, [chatHistory]);

  const handleSendMessage = (event) => {
    // send event to google analytics
    ReactGA.event({ 
      category: 'User',
      action: 'Message Sent',
      label: newMessage,
    });
    event.preventDefault();
    if (!newMessage.trim()) return;

    const newId = chatHistory.length + 1;
    const updatedHistory = [...chatHistory, { id: newId, query: newMessage, sender: 'sent' }];
    setChatHistory(updatedHistory);
    setNewMessage('');
    postMessage(newMessage).then((data) => {
        const updatedHistory2 = [...updatedHistory, { id: updatedHistory.length + 1, query: data.result, sender: 'received' }];
        setChatHistory(updatedHistory2);
        setChatHistoryAPI(data.chat_history);
        });
  };

//   const convertChatHistory = (chat_history) => {
//     const messages = [];
//     // for each with index
//     chat_history.forEach((message, index) => {
//         messages.push({ id: messages.length + 1, query: message.question, sender: 'sent' });
//         messages.push({ id: messages.length + 1, query: message.answer, sender: 'received' });
//     });
//     return messages;
//   }

  const postMessage = async (message) => {
    try {
      setIsTyping(true);
      const response = await fetch('https://3x8ydrtwnj.execute-api.us-east-1.amazonaws.com/dev/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          input: message,
          chat_history: chatHistoryAPI,
        }),
      });
      const data = await response.json();
      console.log(data);
      setIsTyping(false);
      return data;
    } catch (error) {
      console.error('Error:', error);
      setIsTyping(false);
      alert('An error occurred while sending the message, please try again.');
    }
  }

  return (
    <div className="flex flex-col h-screen bg-gray-50 w-full max-w-md mx-auto rounded-lg shadow-lg">
      <div id="chatContainer" className="flex-grow overflow-auto p-4 space-y-2 w-full"
      style={{
        display: 'flex',
        flexDirection: 'column-reverse',
        gap: '0.5rem',
      }}>
        {reversedChatHistory.map((message) => (
          <div
            key={message.id}
            className={`p-3 rounded-lg shadow text-gray-800 ${message.sender === 'sent' ? 'bg-blue-500 text-white' : 'bg-white'} max-w-1/2`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: 'fit-content',
              alignSelf: message.sender === 'sent' ? 'flex-end' : 'flex-start',
            }}
          >
            <pre className="text-sm word-wrap overflow-wrap break-words whitespace-pre-wrap overflow-auto"
            style={{
              textAlign: message.sender === 'sent' ? 'right' : 'left',
            }}
            >
          {/* if there is any link then make it */}
            {message.query}
          </pre>
          </div>
        ))}
      </div>
      {/* Typing text show with animation */}
      {isTyping && (
      <div className="p-4">
        <p className="text-gray-600 text-sm text-left">
          <span className="mr-2">Typing...</span>
          <span className="inline-block w-3 h-3 bg-gray-600 rounded-full animate-bounce"></span>
        </p>
      </div>
      )}
      <div className="p-4 bg-white border-t">
        <form onSubmit={handleSendMessage} className="flex gap-2">
          <input
            type="text"
            disabled={!isTextBoxActive}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message here..."
            className="flex-grow p-3 border rounded-lg focus:ring-blue-500 focus:border-blue-500 transition duration-200 ease-in-out text-gray-800 text-sm"
          />
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg focus:outline-none shadow-lg transition duration-150 ease-in-out text-sm"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChatApp;
